var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row d-flex justify-content-start align-content-center"},[_vm._l((_vm.mobileMenu),function(item,index){return _c('router-link',{key:index,staticClass:"mt-2",attrs:{"to":item.link},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item d-flex justify-content-center align-content-center col-4",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link justify-content-center text-center pb-1",attrs:{"href":href},on:{"click":navigate}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIconByKey(item.icon_2, { class: 'w-95px h-85px ' }))}}),_c('br'),_c('span',{staticClass:"menu-text mb-3 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])])])]}}],null,true)})}),_c('section',{staticClass:"splide",attrs:{"id":"main-carousel"}},[_c('div',{staticClass:"splide__track"},[_c('ul',{staticClass:"splide__list"},[_c('li',{staticClass:"splide__slide"},[_c('div',{staticClass:"row mb-7"},[_c('div',{staticClass:"col-2"},[_c('span',{domProps:{"innerHTML":_vm._s(
                    _vm.getIconByKey('icons.menu.meting', { class: 'w-50px h-40px ' })
                  )}})]),_c('div',{staticClass:"col-10"},[_vm._v(" Şu an müşteriniz River Islan ile bir görüşme gerçekleştirmektedir ")])])]),_c('li',{staticClass:"splide__slide"},[_c('div',{staticClass:"row mb-7"},[_c('div',{staticClass:"col-2"},[_c('span',{domProps:{"innerHTML":_vm._s(
                    _vm.getIconByKey('icons.menu.meting', { class: 'w-50px h-40px ' })
                  )}})]),_c('div',{staticClass:"col-10"},[_vm._v(" Şu an müşteriniz River Islan ile bir görüşme gerçekleştirmektedir 2 ")])])]),_c('li',{staticClass:"splide__slide"},[_c('div',{staticClass:"row mb-7"},[_c('div',{staticClass:"col-2"},[_c('span',{domProps:{"innerHTML":_vm._s(
                    _vm.getIconByKey('icons.menu.meting', { class: 'w-50px h-40px ' })
                  )}})]),_c('div',{staticClass:"col-10"},[_vm._v(" Şu an müşteriniz River Islan ile bir görüşme gerçekleştirmektedir 3 ")])])])])])])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }